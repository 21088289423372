/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode } from "react"
import "./layout.css"

type LayoutProps = {
  children: ReactNode
  hideFooter?: boolean
}

const Layout = ({ children, hideFooter }: LayoutProps) => {
  return (
    <>
      <div
        style={{
          margin: `0 auto`,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        {!hideFooter && (
          <footer>
            <p>Copyright © {new Date().getFullYear()} synchronicityventures</p>
          </footer>
        )}
      </div>
    </>
  )
}

export default Layout
